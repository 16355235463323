<template>
  <div class="base-content">
    <query-frame
      :pageVisible="false"
      @on-query="handleQuery"
      @on-reset="handleReset"
      @on-add="handleAdd"
      @on-page-change="handlePageChange"
      @on-size-change="handleSizeChange"
    >
      <!-- 查询条件开始 -->
      <template slot="query">
        <!--<query-item label="门店名称">-->
          <!--<a-input v-model="query.storeName" placeholder="门店名称"></a-input>-->
        <!--</query-item>-->
<!--        <query-item label="规则名称">-->
<!--          <a-input v-model="query.name" placeholder="规则名称" allowClear></a-input>-->
<!--        </query-item>-->
        <!--<query-item label="优惠方式">-->
          <!--<a-select v-model="query.type" >-->
            <!--<a-select-option :value="1">-->
              <!--满赠-->
            <!--</a-select-option>-->
            <!--<a-select-option :value="2">-->
              <!--满减-->
            <!--</a-select-option>-->
          <!--</a-select>-->
        <!--</query-item>-->
        <query-item label="规则状态">
          <a-select v-model="query.status" allowClear>
            <a-select-option :value="0">
              启用
            </a-select-option>
            <a-select-option :value="1">
              禁用
            </a-select-option>
          </a-select>
        </query-item>

      </template>
      <!-- 查询条件结束 -->

      <!-- 查询结果 开始 -->
      <template slot="table">
        <i-table :columns="columns" :dataSource="dataList">
        </i-table>
      </template>
      <!-- 查询结果 结束 -->
    </query-frame>
    <a-modal
      v-model="subSendVisible"
      width="800px"
      height="600px"
      :maskClosable="false"
      @ok="handleSendSub"
    >
      <div>
        <!-- <div :style="{ borderBottom: '1px solid #E9E9E9' }">
          <a-checkbox
            :indeterminate="indeterminate"
            v-model:checked="isChecked"
            @change="onCheckAllUserCouponChange"
          >
            全部成员
          </a-checkbox>
        </div> -->

<!--        <div>-->
<!--          <a-form-model layout="inline" :disabled="isChecked">-->
<!--            <a-form-model-item label="门店">-->
<!--              <a-input v-model="MemberIphone">-->
<!--              </a-input>-->
<!--            </a-form-model-item>-->
<!--            <a-form-model-item>-->
<!--              <a-button type="primary" @click="FindMemberList()">-->
<!--                查询-->
<!--              </a-button>-->
<!--            </a-form-model-item>-->
<!--            <a-form-model-item>-->
<!--              <a-button type="primary" html-type="reset"> 重置 </a-button>-->
<!--            </a-form-model-item>-->
<!--          </a-form-model>-->
<!--        </div>-->
        <br />
        <div style="width: 100%">
          <i-table
            ref="subShopTable"
            :columns="subShopColumns"
            :dataSource="storeList"
            @selectRowChange="selectRowChange"
          >
          </i-table>
          <!-- <j-select-multiple
            :readOnly="isChecked"
            style="width: 100%"
            v-model="currentCheckedList"
            :options="userPlainOptions"
          /> -->
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import {
  rechargeRuleList, rechargeRuleStatus, chargeRuleDelete, rechargeRuleMinShow,subRechargeRuleSubmit
} from '@/api/recharge';
import queryMixin from '@/mixin/queryMixin';
import { formatDate } from '@/libs/utils';
import { getOwnShopList } from '@/api/store';

export default {
  name: 'ChargeRuleList',
  mixins: [queryMixin],
  components: {
  },
  props: {},
  data() {
    return {
      query: {
        storeName: null,
        name: null,
        status: null,
      },
      // 默认展示表格
      columns: [
        {
          title: '序号',
          ellipsis: true,
          width: 80,
          customRender(text, item, index) {
            return index + 1;
          }
        },
        // {
        //   title: '门店名称',
        //   dataIndex: 'storeName',
        //   key: 'storeName',
        //   ellipsis: true,
        //   width: 200,
        // },
        {
          title: '规则名称',
          dataIndex: 'title',
          key: 'title',
          ellipsis: true,
        },
        {
          title: '达标金额',
          dataIndex: 'amount',
          key: 'amount',
          ellipsis: true,
        },
        {
          title: '赠送金额',
          dataIndex: 'amountDiscount',
          key: 'amountDiscount',
          ellipsis: true,
        },
        // {
        //   title: '充值信息',
        //   dataIndex: 'rule',
        //   key: 'rule',
        //   ellipsis: true,
        //   customRender: (text, row) => {
        //     return (
        //       <section>
        //         {row.list.map((item, index) => {
        //           return <p>{index + 1}.满{item.amount}元，赠{item.amountDiscount}元</p>
        //         })}
        //       </section>
        //     )
        //   }
        // },
        {
          title: '有效时间',
          dataIndex: 'endTime',
          key: 'endTime',
          ellipsis: true,
          width: 260,
          customRender: (text, row) => {
            return (
              <section>{row.periodType === 0 ? '长期有效' : `${row.startDate} - ${row.endDate}`}</section>
            );
          }

        },
        {
          title: '状态',
          dataIndex: 'status',
          key: 'status',
          width: 120,
          customRender: (text, row) => {
            return (
              <a-switch checked-children="启用" un-checked-children="禁用" checked={row.status === 0} onClick={ () => this.handleStatus(row)}/>
            );
          }
        },
        {
          title: '小程序',
          dataIndex: 'minShow',
          key: 'minShow',
          width: 120,
          customRender: (text, row) => {
            return (
              <a-switch checked-children="显示" un-checked-children="隐藏" checked={row.minShow === 0} onClick={ () => this.handleMinShow(row)}/>
            );
          }
        },
        {
          title: '操作',
          dataIndex: 'action',
          key: 'action',
          fixed: 'right',
          width: 260,
          scopedSlots: { customRender: 'action' },
          customRender: (text, row) => {
            return {
              children: <section>
                <a-button type="link" size="small" onClick={ () => this.handleInfo(row) }>查看</a-button>
                <a-divider type="vertical"></a-divider>
                <a-button type="link" size="small" onClick={ () => this.handleEdit(row) }>修改</a-button>
                <a-divider type="vertical"></a-divider>
                <a-button type="link" size="small" onClick={ () => this.handleDelete(row) }>删除</a-button>
                <a-divider type="vertical" v-show={this.isStoreUser}></a-divider>
                <a-button v-show={this.isStoreUser} type="link" size="small" onClick={ () => this.handleSubShop(row) }>下发</a-button>
              </section>,
            };
          }
        },
      ],
      dataList: [],
      // 下发
      subSendVisible: false,
      subShopColumns: [
        // {
        //   title: '门店Id',
        //   dataIndex: 'shopId',
        //   key: 'shopId',
        //   ellipsis: true,
        // },
        {
          title: '门店名称',
          dataIndex: 'shopName',
          key: 'shopName',
          ellipsis: true,
        }
      ],
      storeList: [],
      selectedShopIdList: [],
      selectedRechargeRuleList: []
    };
  },
  computed: {
    isStoreUser() {
      const userInfo = this.$store.getters.getUserInfo;
      return userInfo.userDTO.shopId === 0;
    }
  },
  watch: {},
  created() {
    this.handleQuery();
    if (this.isStoreUser) {
      this.getOwnStoreList();
    }
  },
  mounted() {
  },
  destroyed() {
  },
  methods: {
    getOwnStoreList() {
      getOwnShopList().then((data) => {
        this.storeList = data;
        this.storeList.forEach((item) => {
          item.key = item.shopId;
          item.id = item.shopId;
        });
      });
    },
    // 获取列表
    getData() {
      // this.params.pageIndex = this.page.current;
      // this.params.pageSize = this.page.pageSize;

      this.dataList = [];
      if (this.query.status == null) {
        this.params.status = 2;
      }
      // console.log(this.params);
      rechargeRuleList(this.params).then((data) => {
        data.forEach((item) => {
          if (item.startDate) {
            item.startDate = formatDate(item.startDate);
          }
          if (item.endDate) {
            item.endDate = formatDate(item.endDate);
          }
        });
        this.dataList = data;
      });
    },

    // 添加
    handleAdd() {
      this.$router.push({
        name: 'chargeRuleAdd'
      });
    },

    handleInfo(row) {
      this.$router.push({
        name: 'chargeRuleInfo',
        params: {
          id: row.shopRechargeRuleId
        }
      });
    },
    // 编辑
    handleEdit(row) {
      this.$router.push({
        name: 'chargeRuleEdit',
        params: {
          id: row.shopRechargeRuleId
        }
      });
    },
    // 启用或者禁用
    handleStatus(row) {
      const params = {
        shopRechargeRuleId: row.shopRechargeRuleId,
        status: row.status === 0 ? 1 : 0
      };

      rechargeRuleStatus(params).then(() => {
        this.$message.success('操作成功！');
        this.getData();
      });
    },
    handleMinShow(row) {
      const params = {
        shopRechargeRuleId: row.shopRechargeRuleId,
        minShow: row.minShow === 0 ? 1 : 0
      };

      rechargeRuleMinShow(params).then(() => {
        this.$message.success('操作成功！');
        this.getData();
      });
    },
    // 删除
    handleDelete(row) {
      const self = this;
      const params = {
        shopRechargeRuleId: row.shopRechargeRuleId
      };
      this.$confirm({
        title: '删除充值规则',
        content: '此操作将永远删除该充值规则，您确定要这么做吗？',
        okType: 'danger',
        onOk() {
          return new Promise((resolve, reject) => {
            console.log('删除充值规则');
            chargeRuleDelete(params).then(() => {
              self.$message.success('删除成功');
              self.getData();
              resolve();
            }).catch(() => {
              reject();
            });
          }).catch((e) => console.log(e));
        },
        onCancel() {},
      });
    },
    handleSubShop(row) {
      // 打开下发界面
      if (this.$refs.subShopTable) {
        this.$refs.subShopTable.resetSelect();
      }
      this.subSendVisible = true;
      this.selectedRechargeRuleList = [];
      this.selectedShopIdList = [];
      this.selectedRechargeRuleList.push(row);
    },
    handleSendSub() {
      // 下发
      console.log('下发');
      if (this.selectedShopIdList.length > 0) {
        const params = {
          shopIdList: this.selectedShopIdList,
          shopRechargeRuleList: this.selectedRechargeRuleList
        };
        const that = this;
        // console.log(params);

        subRechargeRuleSubmit(params).then((res) => {
          if (res) {
            that.$message.success('下发成功');
          } else {
            that.$message.error('下发失败');
          }
          that.subSendVisible = false;
          this.selectedShopIdList = [];
          this.selectedRechargeRuleList = [];
        });
      } else {
        this.$message.error('请选择要下发的门店');
        return false;
      }
    },
    selectRowChange(data) {
      this.selectedShopIdList = data;
      console.log(`selectedShopIdList:${data}`);
    },
  }
};
</script>

<style lang="less" scoped>

</style>

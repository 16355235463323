import axios from '../libs/axios';

// 充值列表
const rechargeList = (data) => {
  return axios.get('/api/trd/recharge/list', data);
};

// 充值详情
const rechargeInfo = (orderId) => {
  return axios.get(`/api/trd/recharge/info/${orderId}`);
};

// 提交充值订单
const rechargeSubmit = (data) => {
  return axios.post('/api/trd/recharge/save', data);
};

// 充值规则列表
const rechargeRuleList = (data) => {
  return axios.get('/api/trd/recharge/rule/list', data);
};

// 充值规则详情
const rechargeRuleInfo = (data) => {
  return axios.get('/api/trd/recharge/rule/detail', data);
};

// 提交充值规则
const rechargeRuleSubmit = (data) => {
  return axios.post('/api/trd/recharge/rule/save', data);
};

// 下发充值规则
const subRechargeRuleSubmit = (data) => {
  return axios.post('/api/trd/recharge/rule/sub/save', data);
};

// 充值规则-变更状态
const rechargeRuleStatus = (data) => {
  return axios.get('/api/trd/recharge/rule/status', data);
};

// 充值规则-变更状态
const rechargeRuleMinShow = (data) => {
  return axios.get('/api/trd/recharge/rule/minShow', data);
};

// 正常的充值規則列表
const rechargeRules = (data) => {
  return axios.get('/api/trd/recharge/rules', data);
};

// 最優匹配推薦規則

const rechargeRulerecommend = (data) => {
  return axios.get('/api/trd/recharge/rulerecommend', data);
};
// 订单导出
const rechargeExport = (data) => {
  return axios.get('/api/trd/recharge/export', data);
};
// 删除
const chargeRuleDelete = (data) => {
  return axios.get('/api/trd/recharge/rule/delete', data);
};
export {
  rechargeList,
  rechargeInfo,
  rechargeSubmit,
  rechargeRuleList,
  rechargeRuleInfo,
  rechargeRules,
  rechargeRuleSubmit,
  rechargeRuleStatus,
  rechargeRulerecommend,
  rechargeExport,
  chargeRuleDelete,
  rechargeRuleMinShow,
  subRechargeRuleSubmit
};
